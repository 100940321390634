export const DEFAULT_SPEED = 10; // mph

export enum StorageKey {
    // Used for time estimates. In miles per hour.
    SPEED = 'speed',
    REVERSE_ROUTE = 'reverseroute',
}

export function getFromStorage(key: StorageKey.SPEED): number;
export function getFromStorage(key: StorageKey.REVERSE_ROUTE): boolean;
export function getFromStorage(key: StorageKey): any {
  let val: any;
  try {
    val = JSON.parse(localStorage.getItem(key)!);
  } catch {}

  switch (key) {
    case StorageKey.SPEED:
      return (isNaN(val) ? DEFAULT_SPEED : val) || DEFAULT_SPEED;
    case StorageKey.REVERSE_ROUTE:
      return Boolean(val);
    default:
      acceptsNever(key);
  }
}

export function saveToStorage(key: StorageKey.SPEED, value: number): void;
export function saveToStorage(key: StorageKey.REVERSE_ROUTE, value: boolean): void;
export function saveToStorage(key: StorageKey, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch {}
}

function acceptsNever(arg: never) {
  // This is here so that the compiler flips out if you pass in a value.
  // This is good for switch default cases to make sure you cover everything.
}
