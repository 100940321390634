import { setShown } from './util/dom';
import { KmzData, Coordinate } from './util/kmz-data';
import { get as dbGet } from './util/db';

// Install the handlebar helpers.
import * as Handlebars from 'handlebars';
import './handlebar-helpers';

import { computeMileMarker } from './util/gps';
import { registerServiceWorker } from './util/service-worker-client';
import { CURRENT_MILE_UNKNOWN, State } from './app/state';
import { ViewManager } from './app/view_manager';
import { roundToDecimal } from './util/types';
import { assert } from './util/assert';

let initialized = false;
let state: State|null = null;

async function initialize() {
  registerServiceWorker();

  const kmzData = await dbGet();
  if (!kmzData) {
    window.location.href = '/import-kmz.html';
    return;
  }

  let requestingPreInitPosition = true;
  let preInitPosition: ParsedGeoLocation|null = null;
  requestPosition(
    (loc: ParsedGeoLocation) => {
      requestingPreInitPosition = false;
      if (!initialized) {
        preInitPosition = loc;
      } else {
        updateStateWithNewLocation(loc);
      }
    },
    () => {
      requestingPreInitPosition = false;
    });

  // Process the data.
  // TODO - this is gross, modifying the raw data that comes back from the DB.
  for (const spot of kmzData.spots) {
    if (spot.description) {
      spot.description = new Handlebars.SafeString(spot.description as string);
    }
  }

  state = createState(kmzData, preInitPosition, requestingPreInitPosition);
  setShown('loading', false);
  new ViewManager(state, startLocationUpdate);

  setInterval(startLocationUpdate, 30000);

  initialized = true;
}

function startLocationUpdate() {
  assert(state);
  state.update({locationUpdating: true});
  requestPosition(
    (loc: ParsedGeoLocation) => {
      updateStateWithNewLocation(loc);
    },
    () => {
      state!.update({locationUpdating: false});
    });
}

function createState(kmzData: KmzData,
                     preInitPosition: ParsedGeoLocation|null,
                     requestingPreInitPosition: boolean): State {
  const currentMile = calculateMileFromPos(preInitPosition, kmzData);
  const locationLastUpdated = preInitPosition ? preInitPosition.lastUpdated : '';
  const locationAccuracy = preInitPosition ? preInitPosition.accuracy : '';
  return new State(kmzData, currentMile, requestingPreInitPosition,
      locationLastUpdated, locationAccuracy);
}

interface ParsedGeoLocation {
  coord: Coordinate,
  lastUpdated: string,
  accuracy: string,
}

function parseGeoLocation(pos: GeolocationPosition): ParsedGeoLocation {
  return {
    coord: {lat: pos.coords.latitude, lon: pos.coords.longitude},
    lastUpdated: new Date(pos.timestamp).toLocaleTimeString(),
    accuracy: roundToDecimal(pos.coords.accuracy),
  }
}

function updateStateWithNewLocation(loc: ParsedGeoLocation) {
  const currentMile = calculateMileFromPos(loc, state!.kmz);
  state!.update({
    currentMile,
    locationUpdating: false,
    locationAccuracy: loc.accuracy,
    locationLastUpdated: loc.lastUpdated,
  })
}

function calculateMileFromPos(loc: ParsedGeoLocation|null, kmz: KmzData): number {
  if (!loc) {
     return CURRENT_MILE_UNKNOWN;
  }
  return computeMileMarker(kmz.route, loc.coord);
}

function requestPosition(onSuccess: (loc: ParsedGeoLocation) => void, onError: () => void) {
  navigator.geolocation.getCurrentPosition(
    (pos) => {
      onSuccess(parseGeoLocation(pos));
    },
    error => {
      alert('Error getting location');
      onError();
    },
    {enableHighAccuracy: true});
}

initialize();
